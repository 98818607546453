<template>
    <div class="misc-wrapper">
        <b-link class="brand-logo">
            <vuexy-logo />
            <h2 class="brand-text text-primary ml-1">
                {{ appName }}
            </h2>
        </b-link>

        <div class="misc-inner p-3">
            <div
                class="
          w-100
          text-center
          d-flex
          flex-column
          align-items-center
          justify-content-center
        "
            >
                <h2 class="mt-5">
                    {{ $t(`pages.${pagename}.msg1`) }}
                </h2>
                <p class="mb-2">
                    {{ $t(`pages.${pagename}.msg2`) }}
                </p>
                <b-button variant="success" class="mb-1 btn-block" :to="{ path: '/' }">
                    {{ $t(`common.back`) }}
                </b-button>
                <b-img fluid :src="imgUrl" :alt="$t(`pages.${pagename}.pagetitle`)" />
            </div>
        </div>
    </div>
</template>

<script>
/* eslint-disable global-require */
import { BLink, BImg, BButton } from 'bootstrap-vue'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import store from '@/store/index'
import { $themeConfig } from '@themeConfig'

export default {
  setup() {
    // App Name
    const {
      appName, appLogoImage, companyName, companyUrl, author,
    } = $themeConfig.webConfigs[document.location.hostname] ? $themeConfig.webConfigs[document.location.hostname] : $themeConfig.app
    return {
      pagename: 'notauthorized',
      appName,
      appLogoImage,
      companyName,
      companyUrl,
      author,
    }
  },
  components: {
    BLink, BImg, BButton, VuexyLogo,
  },
  data() {
    return {
      downImg: require('@/assets/images/pages/not-authorized.svg'),
    }
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.downImg = require('@/assets/images/pages/not-authorized-dark.svg')
        return this.downImg
      }
      return this.downImg
    },
  },
  methods: {
    //
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-misc.scss';
</style>
